import Alpine from 'alpinejs'

// AlpineJS Plugins
import persist from '@alpinejs/persist' // @see https://alpinejs.dev/plugins/persist
import collapse from '@alpinejs/collapse' // @see https://alpinejs.dev/plugins/collapse
import intersect from '@alpinejs/intersect' // @see https://alpinejs.dev/plugins/intersect

// Third Party Libraries
import SimpleBar from 'simplebar' // @see https://github.com/Grsmto/simplebar
import Swiper from 'swiper/bundle' // @see https://swiperjs.com
import flatpickr from 'flatpickr' // @see https://flatpickr.js.org
import Toastify from 'toastify-js' // @see https://apvarun.github.io/toastify-js
import ApexCharts from 'apexcharts' // @see https://apexcharts.com
import Quill from 'quill' // @see https://quilljs.com/
import 'quill-paste-smart' // @see https://github.com/Artem-Schander/quill-paste-smart
/* import * as Gridjs from 'gridjs' // @see https://gridjs.io */

import * as helpers from './utils/helpers' // Helper Functions
import store from './store' // Global Store
import breakpoints from './utils/breakpoints' // Breakpoints Store
import usePopper from './components/usePopper' // Alpine Component
import accordionItem from './components/accordionItem' // Alpine Component
import tooltip from './directives/tooltip' // Alpine Directives

window.SimpleBar = SimpleBar
window.ApexCharts = ApexCharts
window.Swiper = Swiper
window.flatpickr = flatpickr
window.Toastify = Toastify
window.Alpine = Alpine
window.Quill = Quill
/* window.Gridjs = Gridjs */
window.helpers = helpers

Alpine.plugin(persist)
Alpine.plugin(collapse)
Alpine.plugin(intersect)
Alpine.directive('tooltip', tooltip)
Alpine.store('breakpoints', breakpoints)
Alpine.store('global', store)
Alpine.data('usePopper', usePopper)
Alpine.data('accordionItem', accordionItem)

window.onload = (event) => {
  document.querySelectorAll('.summernote').forEach(element => {
    const editor = new Quill(element, {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{
            list: 'ordered'
          }, {
            list: 'bullet'
          }],
          [{
            indent: '-1'
          }, {
            indent: '+1'
          }], // outdent/indent
          [{
            align: []
          }],
          ['clean'] // remove formatting button
        ]
      },
      theme: 'snow'
    }).on('text-change', function (delta, source) {
      $($(element).parent().find('input')[0]).val(element.children[0].innerHTML)
    })

    // editor.enable(false)
  })
}
